/* General Section Styling */
#cvPage {
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* CV Container */
#cv {
  width: 100%;
  max-width: 60rem;
  padding: 2rem;
  background: rgb(50, 50, 50);
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.cvPageTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

/* Description Text */
.cvDesc {
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  text-align: center;
}

.pdfViewer iframe {
  width: 100%;
  height: 600px;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.downloadCV {
  text-align: center;
  margin-top: 2rem;
}

.downloadBtn {
  background-color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  text-decoration: none;
  color: rgb(50, 50, 50);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.downloadBtn:hover {
  background-color: white;
}

/* Media Queries */
@media screen and (max-width: 840px) {
  .cvPageTitle {
    font-size: 2rem;
  }

  .cvDesc {
    font-size: 1rem;
  }

  .pdfViewer iframe {
    height: 500px;
  }

  .downloadBtn {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .cvPageTitle {
    font-size: 1.75rem;
  }

  .cvDesc {
    font-size: 0.9rem;
  }

  .pdfViewer iframe {
    height: 400px;
  }

  .downloadBtn {
    font-size: 0.85rem;
  }
}
