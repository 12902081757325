#progLang {
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#progLang h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
  text-align: center;
}

.innerBox {
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#progLang h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #e0e0e0;
  margin: 1rem 0 0.5rem;
  text-align: left;
}

.progLangContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.languageBar,
.databaseBar,
.serverTechBar {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.languageBar:hover,
.databaseBar:hover,
.serverTechBar:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.languageName,
.databaseName,
.serverTechName {
  flex: 1.5;
  color: #fff;
  font-weight: 500;
  white-space: normal;
  word-break: break-word;
}

.barContainer {
  flex: 3;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  height: 25px;
  overflow: hidden;
  position: relative;
}

.bar {
  background: linear-gradient(90deg, #76ff03, #00e5ff);
  height: 100%;
  transition: width 1s ease-in-out;
}

.skillNote {
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #e0e0e0;
  text-align: center;
}

.languageBars {
  background: rgb(50, 50, 50);
  border-radius: 0.5rem;
}

@media screen and (max-width: 840px) {
  #progLang h2 {
    font-size: 2rem;
  }

  #progLang h3 {
    font-size: 1.5rem;
  }

  .languageBar,
  .databaseBar,
  .serverTechBar {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  #progLang h2 {
    font-size: 1.75rem;
  }

  #progLang h3 {
    font-size: 1.2rem;
  }

  .languageBar,
  .databaseBar,
  .serverTechBar {
    padding: 0.3rem;
  }
}
