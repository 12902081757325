#contactPage {
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactPageTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}

.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}

.name,
.email,
.message {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
}

.submitBtn {
  background: white;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.links .link svg {
  fill: white;
  transition: fill 0.3s ease;
}

.links .link:hover svg {
  fill: #f39c12;
}

.submitBtn:disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.links {
  display: flex;
  flex-wrap: wrap;
}

.link {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
  cursor: pointer;
}
