#works {
  margin: 0 auto;
  min-height: calc(100vh-4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.worksTitle {
  margin: 1rem 0;
  font-size: 3rem;
}

.worksDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 45rem;
}

.worksImages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 65rem;
  cursor: pointer;
}

.worksImg {
  object-fit: cover;
  height: 20rem;
  margin: 0.5rem;
}

.worksBtn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 2rem;
  background: white;
  font-size: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 585px) {
  .worksImg {
    height: 48vw;
  }
}

@media screen and (max-width: 585px) {
  .worksImg {
    height: 48vw;
  }
}
