#intro {
  height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  top: -4rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
  border-radius: 200rem;
}

.introContent {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

.introName {
  color: yellow;
}

.introPara {
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}

.btn {
  background: white;
  margin: 1rem 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btnImg {
  object-fit: cover;
  margin-right: 0.5rem;
  height: 1rem;
}

@media screen and (max-width: 840px) {
  .bg {
    right: -10vw;
  }
  .introContent {
    font-size: 10vw;
  }
  .hello {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 480px) {
  .bg {
    right: -20vw;
  }
}

.animatedTitle {
  display: inline-block;
  animation: fade 3s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}
