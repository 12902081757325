#teams {
  overflow: hidden;
  width: 100vw;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.teamsTitle {
  font-size: 3rem;
  font-weight: 600;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}

.teamsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teamBox {
  width: 100%;
  max-width: 80%;
  margin: 1.5rem 0;
  padding: 2rem;
  border-radius: 0.5rem;
  background: rgb(50, 50, 50);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.teamBox:hover {
  transform: translateY(-5px);
}

.teamIcon {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.teamTitle {
  font-size: 2rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.teamDescription {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.videoWrapper {
  margin: 1rem 0;
}

video {
  width: 100%;
  max-width: 100%; /* Ensures video does not exceed box width */
  border-radius: 0.5rem; /* Matches box style */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the video */
}

.repoLink {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: #4caf50; /* Change color to fit your theme */
  text-decoration: none;
}

.repoLink:hover {
  text-decoration: underline;
}

@media screen and (max-width: 720px) {
  .teamTitle {
    font-size: 2.5rem;
  }

  .teamDescription {
    font-size: 0.9rem;
  }

  .teamBox {
    padding: 1.5rem;
  }

  .teamIcon {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .teamsTitle {
    font-size: 2.5rem;
  }

  .teamTitle {
    font-size: 2rem;
  }

  .teamDescription {
    font-size: 0.8rem;
  }

  .teamBox {
    padding: 1rem;
  }

  .teamIcon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
